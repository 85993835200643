@mixin responsive($breakpoint) {
  @if $breakpoint==phone {
    @media only screen and (max-width: 600px) {
      @content;
    }
  }

  @if $breakpoint==smallTab {
    @media only screen and (max-width: 768px) {
      @content;
    }
  }
  @if $breakpoint==tab {
    @media only screen and (max-width: 1024px) {
      @content;
    }
  }
  @if $breakpoint==lg {
    @media only screen and (min-width: 1240px) {
      @content;
    }
  }
}
