$breakpoints: (
    "phone": 414px,
    "tabletSm": 768px,
    "tablet": 1024px,
    "desktop": 1366px,
    "LGdesktop": 1920px,
) !default;

$media-expressions: (
    "screen": "screen",
    "print": "print",
    "handheld": "handheld",
    "retina2x": (
        "(-webkit-min-device-pixel-ratio: 2)",
        "(min-resolution: 192dpi)",
    ),
    "retina3x": (
        "(-webkit-min-device-pixel-ratio: 3)",
        "(min-resolution: 350dpi)",
    ),
) !default;

$unit-intervals: (
    "px": 1,
    "em": 0.01,
    "rem": 0.1,
) !default;

@mixin media($conditions...) {
    @for $i from 1 through length($conditions) {
        $conditions: set-nth($conditions, $i, parse-expression(nth($conditions, $i)));
    }

    $branches: get-query-branches($conditions);
    $query: "";

    @each $branch in $branches {
        @if (str-length($query) != 0) {
            $query: $query + ", ";
        }

        $query: $query + $branch;
    }

    @media #{$query} {
        @content;
    }
}


@function get-query-branches($expressions) {
    $result: "";
    $has-groups: false;

    // Getting initial snapshot and looking for groups
    @each $expression in $expressions {
        @if (str-length($result) != 0) {
            $result: $result + " and ";
        }

        @if (type-of($expression) == "string") {
            $result: $result + $expression;
        } @else if (type-of($expression) == "list") {
            $result: $result + nth($expression, 1);
            $has-groups: true;
        } @else {
            @warn '#{$expression} is not a valid expression.';
        }
    }

    // If we have groups, we have to create all possible combinations
    @if $has-groups {
        @each $expression in $expressions {
            @if (type-of($expression) == "list") {
                $first: nth($expression, 1);

                @each $member in $expression {
                    @if ($member != $first) {
                        @each $partial in $result {
                            $result: join($result, str-replace-first($first, $member, $partial));
                        }
                    }
                }
            }
        }
    }

    @return $result;
}

@function parse-expression($expression) {
    $operator: "";
    $value: "";
    $element: "";
    $result: "";
    $is-width: true;

    @if (type-of($expression) != "string") {
        @warn 'Expression with type `#{type-of($expression)}` detected, string expected.';
    }

    // Separating the operator from the rest of the expression
    @if (str-slice($expression, 2, 2) == "=") {
        $operator: str-slice($expression, 1, 2);
        $value: str-slice($expression, 3);
    } @else {
        $operator: str-slice($expression, 1, 1);
        $value: str-slice($expression, 2);
    }

    // Checking what type of expression we're dealing with
    @if map-has-key($breakpoints, $value) {
        $result: map-get($breakpoints, $value);
    } @else if map-has-key($media-expressions, $expression) {
        $result: map-get($media-expressions, $expression);
        $is-width: false;
    } @else {
        $result: to-number($value);
    }

    @if ($is-width) {
        $unit: unit($result);
        $interval: 0;

        @if (map-has-key($unit-intervals, $unit)) {
            $interval: map-get($unit-intervals, $unit);
        } @else {
            @warn 'Unknown unit: #{$unit}';
        }

        @if ($operator == ">") {
            $element: "(min-width: #{$result + $interval})";
        } @else if ($operator == "<") {
            $element: "(max-width: #{$result - $interval})";
        } @else if ($operator == ">=") {
            $element: "(min-width: #{$result})";
        } @else if ($operator == "<=") {
            $element: "(max-width: #{$result})";
        } @else {
            @warn '#{$expression} is missing an operator.';
        }
    } @else {
        $element: $result;
    }

    @return $element;
}

@function str-replace-first($search, $replace, $subject) {
    $search-start: str-index($subject, $search);

    @if $search-start == null {
        @return $subject;
    }

    $result: str-slice($subject, 0, $search-start - 1);
    $result: $result + $replace;
    $result: $result + str-slice($subject, $search-start + str-length($search));

    @return $result;
}

@function to-number($string) {
    // Matrices
    $strings: "0" "1" "2" "3" "4" "5" "6" "7" "8" "9";
    $numbers: 0 1 2 3 4 5 6 7 8 9;

    // Result
    $result: 0;
    $divider: 0;
    $minus: false;

    // Looping through all characters
    @for $i from 1 through str-length($string) {
        $character: str-slice($string, $i, $i);
        $index: index($strings, $character);

        @if $character == "-" {
            $minus: true;
        } @else if $character == "." {
            $divider: 1;
        } @else {
            @if type-of($index) != "number" {
                $result: if($minus, $result * -1, $result);
                @return _length($result, str-slice($string, $i));
            }

            $number: nth($numbers, $index);

            @if $divider == 0 {
                $result: $result * 10;
            } @else {
                // Move the decimal dot to the left
                $divider: $divider * 10;
                $number: $number / $divider;
            }

            $result: $result + $number;
        }
    }

    @return if($minus, $result * -1, $result);
}

@function _length($number, $unit) {
    $strings: "px" "cm" "mm" "%" "ch" "pica" "in" "em" "rem" "pt" "pc" "ex" "vw" "vh" "vmin" "vmax";
    $units: 1px 1cm 1mm 1% 1ch 1pica 1in 1em 1rem 1pt 1pc 1ex 1vw 1vh 1vmin 1vmax;
    $index: index($strings, $unit);

    @if type-of($index) != "number" {
        @warn 'Unknown unit `#{$unit}`.';
        @return false;
    }

    @return $number * nth($units, $index);
}

/**
*
* Testing
*
**/
