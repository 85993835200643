//  Core

// Dropdown menu

.dropdown-menu {
  & > .bg-composed-wrapper {
    margin: (-($dropdown-padding-y)) -1px -2px;
    z-index: 5;
    top: -1px;
  }

  & > .scroll-area {
    height: 20rem;
  }
}

.user-profile-menu {
  .MuiMenu-paper {
    max-height: fit-content !important;
  }
}

.dropdown-menu-lg {
  min-width: 20rem;
}

.dropdown-menu-medium {
  min-width: 15rem;
}

.dropdown-menu-xl {
  min-width: 18rem;
}

.dropdown-menu-xxl {
  min-width: 21rem;
}

.dropdown-toggle::after {
  position: relative;
  top: 2px;
}

.list-item {
  text-align: center;
  align-content: center;
  cursor: pointer;
  &:hover {
    background-color: transparent !important;
    color: black;
  }
  .button {
    color: grey;
    &:hover {
      color: black;
    }
  }
}

.list-item-content {
  display: "flex" !important;
  justify-content: "center" !important;
  padding-bottom: "0";
  padding-top: "1rem";
  background-color: "transparent" !important;
}
