.hero-wrapper {
  flex-direction: column;
  position: relative;

  .header-nav-wrapper {
    z-index: 9;
  }

  .bg-composed-wrapper--image {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: inherit;

    @media only screen and (max-width: 1024px) {
      background-position: center center;
    }
  }
  .pos-center {
    background-position: center !important;
  }
  .hero-footer {
    z-index: 7;
  }

  .hero-indicator {
    position: absolute;
    right: 0;
    width: 40px;
    text-align: center;
    left: 50%;
    margin-left: -20px;
    bottom: ($spacer * 2);
  }
}
.font-2-MOB {
  @media only screen and (max-width: 600px) {
    font-size: 2rem;
  }
}
