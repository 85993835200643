//  Core

html,
body {
  -ms-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
}

body {
  overflow-x: hidden;
}

.app-wrapper {
  display: flex;
  flex-direction: column;
  flex: 1;
  transition: all 0.5s $app-sidebar-transition;
}

.app-main {
  position: relative;
  min-height: 100vh;
}

.app-content {
  flex: 1;
  display: flex;
  padding: 0;
  flex-direction: column;
  transition: all 0.5s $app-sidebar-transition;
  padding-top: $header-height;
  background: #f9f9f2 !important;

  &--inner {
    padding: $layout-spacer $layout-spacer ($layout-spacer / 2);
    display: flex;
    flex: 1;
    @include media-breakpoint-down(xs) {
      padding: $layout-spacer ($layout-spacer / 2) ($layout-spacer / 2) ($layout-spacer / 2);
    }
    &__wrapper {
      display: block;
      width: 100%;
    }
  }
}

.app-content-sidebar-fixed {
  min-height: 100vh;

  @include media-breakpoint-up(lg) {
    padding-left: $sidebar-width;
  }
}

.app-main-sidebar-static {
  display: flex;
}

.app-content-footer-fixed {
  overflow: hidden;
}
.app-content-sidebar-collapsed {
  @include media-breakpoint-up(lg) {
    padding-left: $sidebar-width-collapsed / 8;
  }
}
